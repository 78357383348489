/**
 * publicite.js
 */

'use strict';

var postscribe = require('postscribe');
var load = require('load-script');
var ready = require('domready');

// CSS selectors for ad positions
var positions = {
  'Top': ['#eAdBlkLargeBanner .adCtnt', '#aTopPubBlk .adCtnt'],
  'Middle': ['#adVdoBlk .ctnt', '#adVdoBlkMiddle .adCtnt', '#pubMiddle', '#adMiddle'],
  'x01': ['#adSkyscrapper01'],
  'x02': ['#adSkyscrapper02']
};


// check for multiple loads
if (global.OAS_AD) {
  throw new Error("Erreur d'initialisation des publicités. Multi chargement");
}
global.OAS_AD = function() {};


// start on DOMready
ready(function() {
  loadNuggad();
  if (global.AudienceScience) {
    // load ads immediatly after Audience Science is finished or after 300ms
    loadAS(loadOAS);
    setTimeout(loadOAS, 300);
  } else {
    // we can load OAS immediatly if there is no need for Audience Science
    loadOAS();
  }
});

var OASloaded = false;
function loadOAS() {
  if (!OASloaded && global.OAS_listpos && global.OAS_sitepage) {
    OASloaded = true;
    loadAds(global.OAS_listpos, global.OAS_sitepage);
  }
}

function loadAds(listpos, sitepage) {
  global.OAS_url = 'http://pub.ftv-publicite.fr/4/' +
    sitepage +
    "/1" +
    (Math.random() + "").substring(2, 11) +
    "@" +
    listpos;

  // get recommendation params from Krux and Audience Science
  var params = getKruxParams().concat(getASParams());
  if (params.length)
    global.OAS_url += '?' + params.join('&');

  load(global.OAS_url, function(err, script) {
    if (!err) {
      insertAds(listpos);
    }
  });
}


function insertAds(listpos) {
  var ls = listpos.split(',');
  for (var i = 0; i< ls.length; i++) {
    var id = ls[i];

    // don't accept invalid ids
    if (!(id in positions)) break;

    // get dom element, by default '#eShowPub{id} .adCtnt',
    var el = document.querySelector('#eShowPub' + id + ' .adCtnt');
    // fallback on position list for old sites
    var j = positions[id].length;
    while (!el && j--) {
      el = document.querySelector(positions[id][j]);
    }

    // create element for invisible ads (eg site takeover)
    if (!el) {
      el = document.createElement('div');
      el.setAttribute('id', 'invisibleAdBlock' + id);
      el.setAttribute('style', 'display:none');
      document.body.appendChild(el);
    }

    if (typeof global.OAS_RICH === 'function') {
      el.innerHTML = ''; // just in case
      postscribe(el, '<script>OAS_RICH("' + id + '")</script>',
        {done: trigger(el)}
      );
    }
  }

  function trigger(el) {
    // trigger custom jquery event for some sites
    return function() {
      if (global.jQuery) {
        global.jQuery(el).trigger('onAdLoaded');
      }
    };
  }
}

// tag Krux
// the main tag is loaded in audience.js
function getKruxParams() {
  // init Krux object if necessary
  if (!global.Krux) {
    (global.Krux = function() {
      global.Krux.q.push(arguments);
    }).q = [];
  }
  var Krux = global.Krux;

  Krux.user = retrieve('user');
  Krux.segments = retrieve('segs');

  var oas_params = [];
  if (Krux.user)
    oas_params.push('kuid=' + Krux.user);
  if (Krux.segments)
    oas_params.push('ksg=' + Krux.segments);
  Krux.oasKeyValues = oas_params.length ? oas_params.join(';') + ';' : '';

  return oas_params;


  // retrieve info from localStorage (if available) or cookie
  function retrieve(n) {
    var m, k = 'kx' + n;
    if (global.localStorage) {
      return global.localStorage[k] || "";
    } else if (navigator.cookieEnabled) {
      m = document.cookie.match(k + '=([^;]*)');
      return (m && decodeURI(m[1])) || "";
    } else {
      return '';
    }
  }
}

function getASParams() {
  var pl = global.asiPlacements;
  var ret = [];
  for (var id in pl) {
    for (var key in pl[id].data) {
      // the asiPlacement object has a completely stupid structure
      ret.push('_RM_HTML_PQ_' + id + '_=' + key);
    }
  }
  return ret;
}


// tag Audience Science
function loadAS(cb) {
  // check for double loading
  if (!global.asiPqTag) {
    // data tag
    var img = new Image();
    img.src = 'http://pix04.revsci.net/F09828/b3/0/3/noscript.gif?D=' +
      encodeURIComponent('DM_LOC=' +
        encodeURIComponent(window.location.href + '&bpid=francetv') +
      '&DM_EOM=1') +'&C=F09828&L=0';
    // reco tag
    load(
      'http://pq-direct.revsci.net/pql?placementIdList=CGGoFv,fKXTTz,udrMUD,7KLJcc,1ykSjt,WUuEkF,uKgaD6&cb=' + new Date().getTime(),
      function() {
        // execute callback
        if (typeof cb === 'function') cb();
      }
    );
  } else {
    if (typeof cb === 'function') cb();
  }
}


// tag Nuggad
function loadNuggad() {
  var nuggUrl = 'https://lpm-francetv.nuggad.net/rc';
  var nuggQs = [
      'nuggn=1003426551',
      'nuggrid=' + encodeURIComponent(global.top.location.href)
    ];

  // tag list matched against url
  var nuggIds = {
    'france2': 1520545807,
    'www.france3': 1259673097,
    'france4': 815690618,
    'france5': 2039603482,
    'pluzz': 1807157810,
    'la1ere': 121201118,
    'franceo': 973798770,
    'leclubfrancetelevisions': 294570857,
    'emploi.francetv': 1881534189,
    'france3-regions.francetvinfo': 182958255,
    'www.francetvinfo': 2095963359,
    'francetvsport': 1142146928,
    'mon-ludo': 149893962,
    'francetv': 239418210
  };

  for (var pattern in nuggIds) {
    if (~document.location.href.indexOf(pattern)) {
      nuggQs.push('nuggsid=' + nuggIds[pattern]);
      break;
    }
  }

  if (nuggQs.length === 3) {
    load(nuggUrl + '?' + nuggQs.join('&'));
  }
}
